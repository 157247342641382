import { useStaticQuery, graphql } from "gatsby"
export const useWpContacts = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wpPage(slug: { eq: "contacts" }) {
          id
          contacts {
            contactDetails
            marqueeText
          }
        }
      }
    `
  )
  return data
}
