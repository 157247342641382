import React from "react"
import Seo from "../components/Seo"
import { useWpContacts } from "../hooks/useWpContacts"
import Layout from "../components/Layout/Layout"
import Marquee from "react-fast-marquee"
import "../styles/pages/contacts.scss"
const ContactsPage = () => {
  const data = useWpContacts()

  return (
    <Layout>
      <Seo title="contacts" />
      <div className="contact-page-container">
        <div className="serif-size top-clearence gv-container">
          <div
            className="contacts"
            dangerouslySetInnerHTML={{
              __html: `${data.wpPage.contacts.contactDetails}`,
            }}
          />
        </div>

        <Marquee pauseOnHover={"true"} loop={0} speed={80}>
          <div
            className="serif-size marquee-text"
            dangerouslySetInnerHTML={{
              __html: `${data.wpPage.contacts.marqueeText}`,
            }}
          />
        </Marquee>
      </div>
    </Layout>
  )
}

export default ContactsPage
